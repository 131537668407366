/* http://prismjs.com/download.html?themes=prism&languages=markup+css+clike+javascript+php+php-extras */
/**
 * prism.js default theme for JavaScript, CSS and HTML
 * Based on dabblet (http://dabblet.com)
 * @author Lea Verou
 */

code[class*="language-"],
pre[class*="language-"] {
    color: white;
    text-shadow: none;
    font-family: Consolas, Monaco, 'Andale Mono', monospace;
    direction: ltr;
    text-align: left;
    white-space: pre;
    word-spacing: normal;
    word-break: normal;
    -moz-tab-size: 4;
    -o-tab-size: 4;
    tab-size: 4;
    -webkit-hyphens: none;
    -moz-hyphens: none;
    -ms-hyphens: none;
    hyphens: none;
}

pre[class*="language-"]::-moz-selection, pre[class*="language-"] ::-moz-selection,
code[class*="language-"]::-moz-selection, code[class*="language-"] ::-moz-selection {
    text-shadow: none;
    background: #b3d4fc;
}

pre[class*="language-"]::selection, pre[class*="language-"] ::selection,
code[class*="language-"]::selection, code[class*="language-"] ::selection {
    text-shadow: none;
    background: #b3d4fc;
}

@media print {
    code[class*="language-"],
    pre[class*="language-"] {
        text-shadow: none;
    }
}

/* Code blocks */
pre[class*="language-"] {
    padding: 1em;
    margin: .5em 0;
    overflow: auto;
}

:not(pre) > code[class*="language-"],
pre[class*="language-"] {
    background: #4e4e4e;
}

/* Inline code */
:not(pre) > code[class*="language-"] {
    padding: .1em;
    border-radius: .3em;
}

.token.comment,
.token.prolog,
.token.doctype,
.token.cdata {
    color: slategray;
}

.token.comment {
    color: #A5A9B0;
}

.token.scope {
    color: #9de2ba;
}

.token.function {
    color: white;
}

.token.punctuation {
    color: #6d9693;
}

.namespace {
    opacity: .7;
}

.token.property,
.token.tag,
.token.boolean,
.token.number,
.token.constant,
.token.symbol {
    color: #905;
}

.token.number {
    color: white;
}

.token.property {
    color: #c5c5c5;
}

.token.tag {
    color: #9de2ba;
}

.token.selector,
.token.attr-name,
.token.string,
.token.builtin {
    color: #ADD2C1;
}

.token.operator,
.token.entity,
.token.url,
.language-css .token.string,
.style .token.string,
.token.variable {
    /*	color: #a67f59;*/
    color: #fdb7b2;
    /*background: hsla(0,0%,100%,.5);*/
}

.token.atrule,
.token.attr-value,
.token.keyword {
    color: #9de2ba;
}

.token.attr-value {
    color: #c5c5c5;
}


.token.regex,
.token.important {
    color: #e90;
}

.token.important {
    font-weight: bold;
}

.token.entity {
    cursor: help;
}
